/* eslint-disable jsx-a11y/anchor-is-valid */
export function FaqSection() {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
          <p id="faqParagraph">
            {" "}
            We're here to give you top notch experience. Here is a list to the most asked queries
          </p>
        </div>

        <div className="faq-list">
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-help-circle icon-help"></i>
              <a id="accordionLinks" data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">
                Where exactly are you located?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                <p id="accordionContent" style={{ padding: "10px 30px 0 30px" }}>
                  TechRise Academy was founded in Maryland, USA, and later established in Beren Building at Moi
                  University, Kesses, Kenya. This strategic move was prompted by the identified gap in Kenya's tech
                  sector. Our presence at Moi University serves as a direct response to this gap, fostering
                  technological advancements and opportunities within the region.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-help-circle icon-help"></i>
              <a id="accordionLinks" data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">
                How much does it cost to attend?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                <p id="accordionContent" style={{ padding: "10px 30px 0 30px" }}>
                  Tuition Fee varies by track. Please contact us through email or the website to get a brochure with the
                  current rates for each of the tracks.
                  <a href="mailto:techrise@kberen.com">Click To Email TechRise Now</a>
                  {/* Due to financial constraints young, ambitious and talented people are facing, TechRise Academy thought
                  it wise not to lock such people out, by offering learning materials, high speed internet, guidance and
                  mentoring for free, then later on after successfully graduating from the program and get placed in
                  your dream job, you'll be obligated to pay 20% of your salary for 2 years, which is a win win
                  situation for all, right? I presume it a Yes!! */}
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i className="bx bx-help-circle icon-help"></i>
              <a id="accordionLinks" data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
                What do I need to Know?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                <p id="accordionContent" style={{ padding: "10px 30px 0 30px" }}>
                  Have basic understanding of programming, that will form a foundation that we can be able to build up
                  on. Be able to type efficiently using both hands, with a reasonable speed, 40wpm is recommended.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i className="bx bx-help-circle icon-help"></i>
              <a id="accordionLinks" data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
                How Do I sign up? <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                <p id="accordionContent" style={{ padding: "10px 30px 0 30px" }}>
                  Fill up the application form on the Home screen, and we will be able to get back to you with the
                  appropriate response.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i className="bx bx-help-circle icon-help"></i>
              <a id="accordionLinks" data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">
                Will you help me find a job?
                <i className="bx bx-chevron-down icon-show"></i>
                <i className="bx bx-chevron-up icon-close"></i>
              </a>
              <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                <p id="accordionContent" style={{ padding: "10px 30px 0 30px" }}>
                  We will give you guidance, career coaching, resume curating services, and references to job sites.
                  However, you will take the primary responsibility of applying for jobs. Upon successful completion of
                  the program, we will be more than happy to put our name behind your skills. We will give you glowing
                  recommendations that will propel you to get your dream job, targeting well paying full stack
                  developer, ui developer, server side developer, deployment professional, and linux administration
                  jobs.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
