import { HttpTypes } from "@kberen/pkg.shared-types";
import { Course } from "./admin";

export interface User {
  userName: string;
  imageUrl: string;
  details: {
    description: string;
    curCourse: string;
    studentId: number;
    phoneNumber: number;
    email: string;
    startDate: number;
    upComingCourses: string[];
    completedCourses: string[];
    currentLectureTitle: string;
    currentLectureNumber: number;
  };
}

export interface Tracker {
  id: number;
  courseId: number;
  enrollmentId: number;
  progress: number;
  pace: number;
  isActive: boolean;
  completed: boolean;
  auditId: number;
  course: {
    id: number;
    name: string;
    totalLecNo: number;
    author: string;
    completionDays: number;
    pace: number;
    auditId: number;
  };
}

export interface CurrentUpdate {
  auditId: number;
  comment: string;
  curLecNo: number;
  id: number;
  isExpected: boolean;
  trackerId: number;
}
export interface EnrolleeInfo {
  email: string;
  fName: string;
  imageUrl: string;
  lName: string;
  phoneNo: string;
}
export interface Update {
  curLecNo: number;
  comment: string;
}

export interface UserDetails {
  enrolleeData: EnrolleeInfo;
  enrollmentDate?: string;
  currentTracker?: Tracker;
  completedTrackers: Tracker[];
  remainingCourses: Course[];
  todayUpdate?: Update;
  testimonial?: string;
  enrollmentId?: number;
}

export interface ReduxState {
  chart: ChartState;
  user: UserDetails;
  sideBar: { showSideBar: boolean };
  updates: { isEditing: boolean; isGivingUpdate: boolean; isGivingTestimonial: boolean };
  auth: { accessToken: string; refreshToken: string; isLoggedIn: boolean; refreshTokenExp: number };
}

export interface RemCourse {
  id: number;
  name: string;
  totalLecNo: number;
  author: string;
  completionDays: number;
  pace: number;
  auditId: number;
}

export type Role = "admin" | "student" | "superAdmin";

export interface ChartState {
  selectedCourse: { name: string; progress: string };
  chartData: [{ value: number; name: "completed" }, { value: number; name: "Incomplete" }];
}

export interface Testimonial {
  id: number;
  comment: string;
  postedOn: Date;
  updatedOn: Date;
}

export interface FetchedTestimonial {
  fName: string;
  lName: string;
  imageUrl: any;
  comment: string;
  postedOn?: Date;
  updatedOn?: Date;
}

export interface StoredTestimonial {
  fName: string;
  lName: string;
  comment: string;
  imageUrl: any;
}

// export type ErrorType = {
//   data: {
//     error: {
//       message: string;
//       status: number;
//     };
//   };
// };

export interface UpdateUserDetails {
  imageUrl?: string;
  fName?: string;
  lName?: string;
  phoneNo?: string;
}

export type ErrorType = {
  data: HttpTypes.HttpErrorRes;
};

export type ShouldGiveUpdatesState = [boolean, (newValue: boolean) => void];

export interface DateRangeStructure {
  start: Date;
  end: Date;
  range: Date[];
}

export enum AttendanceStatus {
  EXPECTED = "Expected",
  UNEXPECTED = "Unexpected",
  EXCUSED = "Excused",
  PRESENT = "Present",
  ABSENT = "Absent",
  PENDING = "Pending",
}

export interface EnrolleeData {
  id: number;
  fName: string;
  lName: string;
  email: string;
  phoneNo: number;
  resumeUrl: string;
  status: string;
  appliedOn: Date;
  processedOn: Date;
  error?: {
    message: string;
  };
}

// export interface MemberData {
//   id: number;
//   fName: string;
//   lName: string;
//   email: string;
//   phoneNo: number;
//   currentCourse: string;
//   curLecNo: number;
//   dateUpdated: number;
//   comment: string;
// }

export type RemainingCourses = {
  id: number;
  name: string;
  totalLecNo: number;
  author: string;
  completionDays: number;
  pace: number;
  auditId: number;
};

export interface MemberData {
  id: number;
  fName: string;
  lName: string;
  email: string;
  phoneNo: number;
  resumeUrl: string;
  status: string;
  auditId: number;
  // For convenience - delete later
  currentCourse: string;
  curLecNo: number;
  dateUpdated: number;
  comment: string;
  imageUrl: string;
  //////////////////////////
}

export interface Student {
  id: number;
  fName: string;
  lName: string;
  email: string;
  status: string;
  imageUrl: string;
  curTrackerId: number;
}

export interface DecodedTokenStructure {
  countryCode: string;
  email: string;
  emailVerified: boolean;
  exp: number;
  fName: string;
  iat: number;
  lName: string;
  phoneNo: string;
  imageUrl: string;
  rememberMe: boolean;
  phoneVerified: boolean;
  role: string;
  sub: string;
}

export interface UpdateData {
  id: number;
  comment: string;
  curLecNo: number;
  trackerId: number;
  postedOn: string;
}

export interface GetTrackerResp {
  id: number;
  courseId: number;
  enrollmentId: number;
  progress: number;
  pace: number;
  isActive: true;
  completed: false;
  auditId: number;
  course: {
    id: number;
    name: string;
    totalLecNo: number;
    author: string;
    completionDays: number;
    pace: number;
    auditId: number;
  };
}

export interface UpdatePost {
  trackerId: number;
  curLecNo: number;
  comment: string;
}

export * from "./enrollment";
export * from "./authorize";
export * from "./admin";
