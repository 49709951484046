import { berenCourses } from "../constants";
import eduImg from "../assets/images/testimonials/edwin.jpg";
import jeromeImg from "../assets/images/testimonials/jerome.jpg";
import ianImg from "../assets/images/testimonials/ian.jpg";
import denzelImg from "../assets/images/testimonials/denzel.jpg";

export const dummyUser = {
  userName: "John Doe",
  imageUrl: "",
  details: {
    description: "An aspiring fullstack developer",
    curCourse: berenCourses.flutter.name,
    studentId: 2525,
    phoneNumber: 788990134,
    email: "johndoe@gmail.com",
    startDate: "28/07/2022",
    upComingCourses: ["Linux Course"],
    completedCourses: [
      berenCourses.javascript.name,
      berenCourses.typesript.name,
      berenCourses.nodeJS.name,
      berenCourses.sqlAndDatabases.name,
      berenCourses.react.name,
      berenCourses.reactNative.name,
    ],
    currentLectureTitle: " Handling user input",
    currentLectureNumber: 221,
  },
};

const jeromeTest =
  "TechRise Academy has made a highly competent software engineer out of the former rookie that was me. I did not have a clear path of what my journey would be in the IT industry. Then came the academy; it showed me a clear path and guided me step by step to the point where I now call myself a competent fullstack developer. The learning resources are rich in content and through this academy I have obtained insights that have pushed me to heights that I never imagined I would reach so quick in my career.";
const edwinTest =
  "Through TechRise Academy, I've managed to learn how to be a team player and be effective in problem solving and analysis. Through the daily scrum meetings, I've gained more confidence in communication and listening skills.Thanks for believing in me giving me a chance to enroll.";
const ianTest =
  "It's exciting to be in your final year of undergraduate and anticipating graduation,that was not the case for me .It was frightening thinking of how under skilled I will be fresh out of campus with theoretical computer science knowledge. Thus came in the academy, from a toddler in javascript ,now proficient and first choice.The details captured through the program makes me more comfortable and crispy than ever before with software development.I can now comfortably call myself a full stack developer - thanks to TechRise Academy.";
const denzelTest =
  "I wanted to make a career change, and TechRise Academy gave me the fundamentals and the confidence to make it happen. This program helped me to start a career that I love. I'm forever grateful to have got this opportunity, it has been a game changer";

export const testimonialArr = [
  {
    fName: "Edwin",
    lName: "Odhiambo",
    comment: edwinTest,
    imageUrl: eduImg,
  },
  {
    fName: "Jerome ",
    lName: "Jumah",
    comment: jeromeTest,
    imageUrl: jeromeImg,
  },

  {
    fName: "Ian",
    lName: "Njoroge",
    comment: ianTest,
    imageUrl: ianImg,
  },
  {
    fName: "Denzel",
    lName: "Kipkemboi",
    comment: denzelTest,
    imageUrl: denzelImg,
  },
];
