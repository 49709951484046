import { LogTypes } from "@kberen/pkg.shared-types";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DOMAIN } from "../constants";
import { CLIENT_ID } from "../constants";

export function sendLogBody(message: string): LogTypes.LogData {
  return {
    level: "debug",
    message: message,
    logId: `ACD-UI${uuidv4()}`,
    sentAt: new Date(),
    desc: "ACD CODE VERIFIER DEBUG 📋📋📋",
  };
}

export function sendLog(message: string) {
  const sendlogUrl = DOMAIN + "/log";
  const getInitDataUrl = DOMAIN + "/init-data";
  const data = sendLogBody(message);
  let clientId = CLIENT_ID;

  async function sendLogAsync() {
    // 1. Fetch clientId if its not present
    if (!CLIENT_ID) {
      try {
        const res = await axios.get(getInitDataUrl);
        const resData = res.data;
        clientId = resData.clientId;
      } catch (err) {
        console.error(err);
      }
    }

    // 2. Send error to logger
    try {
      const headers = {
        "Content-Type": "application/json",
        "Client-Auth": clientId,
      };
      axios.post(sendlogUrl, data, { headers });
    } catch (err) {
      console.error(err);
    }
  }

  sendLogAsync();
}
